import { Button, Text } from '@geist-ui/core';
import { LogIn, LogOut } from '@geist-ui/icons';
import { Form, useNavigate } from '@remix-run/react';
import { useEffect, useState } from 'react'; // Import useState
import { useSelector } from 'react-redux';
import { RootState } from '~/appSlice';
import { wind } from '~/root';
import InputSelector from './InputSelector';

const SettingsComponent = () => {
    const navigate = useNavigate();
    const { spotifyToken, uid } = useSelector((state: RootState) => state.app);
    const [verifier, setVerifier] = useState(''); // State for verifier

    useEffect(() => {
        const generatedVerifier = generateCodeVerifier(128);
        setVerifier(generatedVerifier);
        wind.localStorage.setItem("spotify_verifier", generatedVerifier);
    }, []);

    function generateCodeVerifier(length: number) {
        let text = '';
        let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    return (
        <>
            <div>
                <div style={{ margin: 10, paddingTop: 10 }}>
                    <InputSelector />
                </div>
                {spotifyToken && (
                    <Text>Spotify Authorized!</Text>
                )}
                <div style={{ margin: '20px 5px 0 5px', display: 'flex', alignItems: 'center' }}>
                    <Form method="post">
                        <input type="hidden" name="spotify_verifier" value={verifier} />
                        <input type="hidden" name="redirect_url" value={wind?.location?.href?.replace('/app', '/callback')} />
                        <Button htmlType="submit" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Authorize Spotify</Button>
                    </Form>
                </div>
                <div style={{ margin: '20px 5px 0 5px', display: 'flex', alignItems: 'center' }}>
                    {uid ? <Button auto scale={1} icon={<LogOut />} onClick={() => { navigate('/logout'); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Log Out</Button> :
                        <Button auto scale={1} icon={<LogIn />} onClick={() => { navigate('/login'); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Log In</Button>}
                </div>
            </div>
        </>
    );
};

export default SettingsComponent;
