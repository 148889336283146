import { Button, Spacer, Tabs } from '@geist-ui/core';
import { Codesandbox, Layers, Moon, Settings, Slack, Sun } from '@geist-ui/icons';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { redirect, type ActionFunction, type MetaFunction } from "@remix-run/cloudflare";
import { doc, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { getCurrentUserId } from '~/auth.client';
import LayersComponent from '~/components/LayersComponent';
import PresetsComponent from '~/components/PresetsComponent';
import SettingsComponent from '~/components/SettingsComponent';
import SystemsComponent from '~/components/SystemsComponent';
import Titlebar from "~/components/Titlebar";
import WebGLCanvas, { WebGLCanvasProps } from "~/components/WebGLCanvas";
import { processMessage } from "~/mathProcessor";
import { mathQuillFix } from "~/mathQuillFix";
import { addNewInternalFloat, removeInternalFloatsNotInList } from "~/windowVarHandler";
import {
  AppDispatch,
  initSystems,
  loadInitialVariant,
  loadPresetsAsync,
  loadSystemsAsync,
  RootState,
  setActiveTab,
  setCodeStrings,
  setFunctionStrings,
  setMonitorSize,
  setSliderNames,
  setTrack,
  toggleDarkTheme
} from '../appSlice';
import { wind } from "../root";
let mathjs: any | null = null;

export function loadCDN(url: string, globalName: string): Promise<any> {
  return new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      reject('Window is undefined.');
      return;
    }

    if (wind[globalName]) {
      resolve(wind[globalName]);
      return;
    }

    const script = document.createElement('script');
    script.src = url;
    script.onload = () => {
      resolve(wind[globalName]);
    };
    script.onerror = () => {
      reject(`Failed to load ${globalName} script`);
    };
    document.head.appendChild(script);
  });
}

export const meta: MetaFunction = () => {
  return [
    { title: "Aetherscope" },
    { name: "description", content: "Dive into a trance of mathematics and music." },
  ];
};

export const action: ActionFunction = async ({ context, request }: { context: any, request: Request }) => {
  //@ts-ignore
  const CLIENT_ID = import.meta.env?.VITE_SPOTIFY_CLIENT_ID ?? context.cloudflare?.env?.SPOTIFY_CLIENT_ID;
  const formData = await request.formData();
  const verifier = formData.get("spotify_verifier") as string;
  const redirect_url = formData.get("redirect_url") as string;

  async function generateCodeChallenge(codeVerifier: string) {
    const data = new TextEncoder().encode(codeVerifier);
    const digest = await crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  const challenge = await generateCodeChallenge(verifier);
  const params = new URLSearchParams();
  params.append("client_id", CLIENT_ID);
  params.append("response_type", "code");
  params.append("redirect_uri", redirect_url);
  params.append("scope", "user-read-private user-read-email user-read-currently-playing");
  params.append("code_challenge_method", "S256");
  params.append("code_challenge", challenge);

  return redirect(`https://accounts.spotify.com/authorize?${params.toString()}`);
};

const subscribeToSystems = (systemName: string, callback: (data: any) => void) => {
  return onSnapshot(doc(wind.firebaseFirestore, "Systems", systemName), (docSnapshot) => {
    callback(docSnapshot.data());
  });
};

export const processLatexStrings = createAsyncThunk(
  'latex/processStrings',
  async (latexStrings: Record<string, string>, { dispatch }) => {
    try {
      const data = await processMessage(latexStrings);

      if (!data) { return; }
      console.debug('Got math processed: ', data);
      const foundFloats: string[] = [];

      if (data.sliderFloats && Object.keys(data.sliderFloats).length > 0) {
        dispatch(setSliderNames(data.sliderFloats));
      }

      for (const equation in data.sliderFloats) {
        if (Object.prototype.hasOwnProperty.call(data.sliderFloats, equation)) {
          for (const sliderFloat of data.sliderFloats[equation]!) {
            addNewInternalFloat(sliderFloat);
            foundFloats.push(sliderFloat);
          }
        }
      }

      removeInternalFloatsNotInList(foundFloats);
      dispatch(setCodeStrings(data.equationCodes));
      dispatch(setFunctionStrings(data.functionCodes));
    } catch (error) {
      console.error('Error processing LaTeX strings:', error);
    }
  }
);

export default function AppLayout() {
  const dispatch = useDispatch<AppDispatch>();
  const { monitorSize, systems, presets, activeTab, latexStringsRecord, imageUrl, track, spotifyToken, isDarkTheme, showBars } = useSelector((state: RootState) => state.app);
  const [isClient, setIsClient] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [panelSize, setPanelSize] = useState(0);

  const checkOrientation = () => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
  }, [imageUrl]);

  function updateMonitorSize() {
    const newMonitorSize = {
      width: wind?.screen.width,
      height: wind?.screen.height
    };

    if (JSON.stringify(monitorSize) !== JSON.stringify(newMonitorSize)) {
      dispatch(setMonitorSize(newMonitorSize));
    }
  };

  useEffect(() => {
    setIsClient(true);
    checkOrientation();
    window.addEventListener('resize', checkOrientation);

    if (!presets) {
      dispatch(loadPresetsAsync());
    }

    if (!systems) {
      getCurrentUserId().then((uid) => {
        if (uid) {
          dispatch(loadSystemsAsync(uid));
        } else {
          const name = 'System 1';
          dispatch(initSystems({
            [name]: {
              name: name,
              variants: [{
                name: 'Variant 1',
                customLatexStrings: ['h=x-\\sin \\left(y-g_c\\right)'],
                internalFloats: {}
              }]
            }
          }));
          dispatch(loadInitialVariant(name));
        }
      })
    }

    window.addEventListener('resize', updateMonitorSize);
    updateMonitorSize();
    return () => {
      window.removeEventListener('resize', updateMonitorSize);
      window.removeEventListener('resize', checkOrientation);
    };

  }, [activeTab, dispatch]);

  useEffect(() => {
    const fetchCurrentTrack = async (token: string) => {
      const response = await fetch('https://api.spotify.com/v1/me/player/currently-playing', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        return await response.json();
      }

      return null;
    };

    if (spotifyToken) {
      const fetchTrack = async () => {
        const currentTrack = await fetchCurrentTrack(spotifyToken);
        if (currentTrack) {
          dispatch(setTrack(currentTrack));
        }
      };
      fetchTrack();
      const interval = setInterval(fetchTrack, 1000);
      return () => clearInterval(interval);
    }
  }, [spotifyToken, dispatch]);

  useEffect(() => {
    dispatch(processLatexStrings(latexStringsRecord ?? []));
  }, [latexStringsRecord, isClient, dispatch]);

  const handleThemeChange = useCallback(() => {
    dispatch(toggleDarkTheme());
  }, [dispatch]);

  function formatArtists(track: any): string | undefined {
    if (!track?.['item']?.['artists']) {
      return undefined;
    }

    const artists = track['item']['artists'].map((artist: any) => artist['name']);
    let chars = 0;
    const trimmedArtists: string[] = [];

    for (const artist of artists) {
      trimmedArtists.push(artist);
      chars += artist.length;

      if (chars > 14) {
        break;
      }
    }

    if (trimmedArtists.length === 0) return '';

    if (trimmedArtists.length === 1) return trimmedArtists[0];

    return `${trimmedArtists.slice(0, -1).join(', ')} & ${trimmedArtists[trimmedArtists.length - 1]}`;
  }

  const getMediaSource = (url: string) => {
    if (!url) return undefined;
    const extension = url.split('.')?.pop()?.toLowerCase();
    if (!extension) return 'image';
    const videoExtensions = ['mp4', 'webm', 'ogg', 'mov'];

    return videoExtensions.includes(extension) ? 'video' : 'image';
  };

  const ShaderCanvas = () => {
    const sourceUrl = imageUrl ? imageUrl : undefined
    const mediaSource = sourceUrl ? getMediaSource(sourceUrl) : undefined;

    return (
      <WebGLCanvas
        mediaSource={mediaSource as WebGLCanvasProps['mediaSource']}
        sourceUrl={sourceUrl}
      />
    );
  };

  return (
    <>
      <PanelGroup direction={isPortrait ? "vertical" : "horizontal"} style={{ margin: 0, padding: 0, height: '100vh' }}>
        <Panel defaultSize={isPortrait ? 50 : 30} minSize={isPortrait ? 20 : 0} style={{ margin: 0, padding: 0 }} onResize={(size) => { setPanelSize(size); updateMonitorSize(); }} >
          <div style={{
            background: 'linear-gradient(to right,  #303030, #383838, #454545)',
            width: '100%',
            textAlign: 'center'
          }}>
            <Titlebar />

          </div>
          <div style={{ padding: 0, margin: 0, height: '100%' }}>
            <div style={{ width: '100%', padding: 0 }}>
              <Tabs value={activeTab} onChange={(val) => { dispatch(setActiveTab(val)); mathQuillFix() }}>
                <Tabs.Item label={<><Codesandbox /> Presets</>} value="presets" />
                <Tabs.Item label={<><Slack /> Systems</>} value="systems" />
                <Tabs.Item label={<><Layers /> Layers</>} value="layers" />
                <Tabs.Item label={<><Settings /> Settings</>} value="settings" />
              </Tabs>
            </div>
            <div style={{ paddingRight: 18, paddingLeft: 18, height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
              <div style={{ display: activeTab === 'presets' ? '' : 'none', margin: 0, padding: 0 }}>
                <PresetsComponent />
              </div>

              <div style={{ display: activeTab === 'systems' ? '' : 'none', margin: 0, padding: 0 }}>
                <SystemsComponent />
              </div>

              <div style={{ display: activeTab === 'layers' ? '' : 'none', margin: 0, padding: 0 }}>
                <LayersComponent />
              </div>

              <div style={{ display: activeTab === 'settings' ? '' : 'none', margin: 0, padding: 0 }}>
                <SettingsComponent />
              </div>
              <Spacer h={6} />
            </div>
          </div>
          <PanelResizeHandle
            className="resize-handle"
            style={{
              width: isPortrait ? '100%' : '30px',
              height: isPortrait ? '30px' : '100%',
              backgroundColor: 'transparent',
              position: 'absolute',
              left: isPortrait ? '0' : `calc(${panelSize}% - 15px)`,
              top: isPortrait ? `calc(${panelSize}% - 15px)` : '0',
              right: isPortrait ? '0' : 'auto',
              bottom: isPortrait ? 'auto' : '0',
              cursor: isPortrait ? 'ns-resize' : 'ew-resize',
              zIndex: 2,
            }}
          />
        </Panel>
        <Panel defaultSize={isPortrait ? 50 : 70}
          style={{
            height: isPortrait ? '50vh' : '100vh',
            boxShadow: isPortrait ? '0 -5px 20px rgba(0, 0, 0, 0.4)' : '-5px 10px 20px rgba(0, 0, 0, 0.4)',
            zIndex: 1,
            margin: 0,
            padding: 0
          }}
        >
          <div style={{ backgroundColor: "#151515", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, padding: 0 }}>
            {isClient && ShaderCanvas && (
              <div style={{ backgroundColor: "red", position: 'relative', width: "100vw", height: "100vh" }}>
                <ShaderCanvas />
                {showBars && (
                  <>
                    <span
                      style={{
                        position: 'relative',
                        bottom: '37%',
                        left: '22%',
                        fontWeight: '400',
                        color: '#ffffff',
                        zIndex: 1,
                        pointerEvents: 'none',
                        display: 'block',
                        textShadow: '0px 3px 5px rgba(0, 0, 0, 0.7)',
                      }}
                    >
                      <span
                        style={{
                          display: 'block',
                          fontWeight: '800',
                          fontSize: '70px',
                        }}
                      >
                        {formatArtists(track)?.toUpperCase() ?? ''}
                      </span>
                      <span
                        style={{
                          display: 'block',
                          fontWeight: '400',
                          fontSize: '50px',
                          marginTop: '-1%'
                        }}
                      >
                        {track?.['item'] ? track['item']['name'].toUpperCase() : ''}
                      </span>
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </Panel>
      </PanelGroup>
      <Button
        aria-label="toggle theme" auto scale={2 / 3} px={0.6}
        iconRight={isDarkTheme ? <Moon /> : <Sun />}
        style={{
          position: 'absolute',
          bottom: '16px',
          left: '16px',
          fontSize: '12px',
          padding: '4px',
          width: '40px',
          height: '40px',
        }}
        onClick={handleThemeChange} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
      <div id="resizable">
        <div className="resize-handle bottom-right"></div>
      </div>
    </>
  );
}