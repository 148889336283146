import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Input, Modal, Select, Spacer, Text } from "@geist-ui/core";
import { AppDispatch, RootState, loadVariantFromIndex, setVariant, setCurrentSystem, setSystems } from "../appSlice";

const SystemsComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { systems, currentSystem, currentVariantIndex, guest, currentVariant } = useSelector((state: RootState) => state.app);
  const [selectedSystem, setSelectedSystem] = useState<string | undefined>();
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number | undefined>(currentVariantIndex);
  const [newName, setNewName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<"variant" | "system">("variant");

  const handleSystemChange = (value: string | string[]) => {
    value = value as string;
    setSelectedSystem(value);
    dispatch(setCurrentSystem(systems?.[value]));
    dispatch(loadVariantFromIndex({ systemName: value, index: 0 }));
  };

  useEffect(() => {
    if (currentSystem) {
      setSelectedSystem(currentSystem.name);
    }
  }, []);

  const handleVariantSelect = (index: number) => {
    setSelectedVariantIndex(index);
    if (selectedSystem) {
      dispatch(loadVariantFromIndex({ systemName: selectedSystem, index }));
    }
  };

  const handleSave = () => {
    if (guest) {
      alert("Saving is not available in guest mode. Please log in to save.");
      return;
    }
    if (selectedSystem && selectedVariantIndex !== undefined) {
      dispatch(setVariant({ systemName: selectedSystem, index: selectedVariantIndex }));
    } else if (selectedSystem) {
      dispatch(setCurrentSystem(systems![selectedSystem]));
    }
  };

  const handleCreate = () => {
    if (guest) {
      alert("Creating new items is not available in guest mode. Please log in to create.");
      return;
    }
    if (modalType === "variant" && selectedSystem && newName) {
      const newIndex = systems![selectedSystem]!.variants.length;
      dispatch(setVariant({ systemName: selectedSystem, index: newIndex, name: newName }));
      setSelectedVariantIndex(newIndex);
    } else if (modalType === "system" && newName) {
      const cSystems = systems ?? {}
      cSystems![newName] = { name: newName, variants: currentVariant ? [currentVariant] : [] };
      dispatch(setSystems(cSystems));
      dispatch(setCurrentSystem(cSystems![newName]));
      dispatch(loadVariantFromIndex({systemName: newName, index: 0}));
      setSelectedSystem(newName);
    }
    setIsModalOpen(false);
    setNewName("");
  };

  const openModal = (type: "variant" | "system") => {
    setModalType(type);
    setIsModalOpen(true);
  };

  return (systems &&
    <Card>
      <Card.Content>
        <Text h4>Select System</Text>
        <Select
          placeholder="Select a System"
          value={selectedSystem}
          onChange={handleSystemChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          {Object.keys(systems).map((systemName) => (
            <Select.Option key={systemName} value={systemName}>
              {systemName}
            </Select.Option>
          ))}
        </Select>
        <Spacer h={1} />
        <Button onClick={() => openModal("system")} disabled={guest} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Create New System</Button>
        <Spacer h={1} />
        {(selectedSystem && systems[selectedSystem]) && (
          <>
            <Select
              placeholder="Select a Variant"
              value={selectedVariantIndex?.toString()}
              onChange={(val) => handleVariantSelect(parseInt(val as string))} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
              {systems[selectedSystem]!.variants.map((variant, index) => (
                <Select.Option key={index} value={index.toString()}>
                  {variant.name || `Variant ${index + 1}`}
                </Select.Option>
              ))}
            </Select>
            <Spacer h={1} />
            <Button onClick={handleSave} disabled={guest} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Save Current {selectedVariantIndex !== undefined ? "Variant" : "System"}</Button>
            <Spacer h={0.5} />
            <Button onClick={() => openModal("variant")} disabled={guest} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Create New Variant</Button>
          </>
        )}
      </Card.Content>

      <Modal visible={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Title>Create New {modalType === "variant" ? "Variant" : "System"}</Modal.Title>
        <Modal.Content>
          <Input
            placeholder={`Enter ${modalType} name`}
            value={newName}
            onChange={(e) => setNewName(e.target.value)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined}          />
        </Modal.Content>
        <Modal.Action placeholder="cancel" passive onClick={() => setIsModalOpen(false)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Cancel</Modal.Action>
        <Modal.Action placeholder="create" onClick={handleCreate} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Create</Modal.Action>
      </Modal>
    </Card>
  );
};

export default SystemsComponent;