import { CssBaseline, GeistProvider, Grid } from '@geist-ui/core';
import React from 'react';
import { wind } from '~/root';
import WindowControls from './WindowControls';

const Titlebar: React.FC = () => {
    return (
        <div style={{
            cursor: 'default',
            userSelect: 'none',
            WebkitUserSelect: 'none'
        } as React.CSSProperties}>
            <Grid.Container style={{ margin: 0, padding: 0 }} gap={0.5}>
                <Grid xs={7}>
                    {wind?.env?.electron && (
                        <>
                            <div style={{ '-webkit-app-region': 'drag', width: '100%', height: '48px', position: 'absolute', top: 0, left: 0 } as React.CSSProperties} />
                            <WindowControls />
                        </>
                    )}
                </Grid>
                <Grid xs={17}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            width: '100%',
                            height: '100%',
                            paddingRight: 20,
                            margin: 0,
                            '-webkit-app-region': 'drag'
                        } as React.CSSProperties}
                    >
                        <img src="logo.png" style={{ height: "50px" }} alt="Logo" />
                    </div>
                </Grid>
            </Grid.Container>
        </div>
    );
};

export default Titlebar;