import { FunctionComponent, useEffect, useState } from 'react';
import { wind } from '~/root';

interface MathQuillComponentProps {
  latex: string;
  onChange: (mathField: any) => void;
}

export const MathQuillComponent: FunctionComponent<MathQuillComponentProps> = (props) => {
  const [MathQuill, setMathQuill] = useState<any>(null);

  useEffect(() => {
    if (!MathQuill) {
      if (wind?.env?.electron) {
        setMathQuill(require("react-mathquill"));
      } else {
        import("react-mathquill").then((mq: any) => {
          setMathQuill(mq);
        });
      }
    }
  }, []);

  if (!MathQuill) {
    return null;
  }

  return (
    <MathQuill.EditableMathField
      style={{ width: "100%", height: "100%", marginLeft: 10 }}
      config={{
        autoCommands: 'pi theta psi',
        autoOperatorNames: 'sinh cosh tanh sech sin cos tan sec csc cosec cot ' +
          'csch cosech coth arcsin arccos arctan arcsec arccsc arccosec arccot arcsinh ' +
          'arccosh arctanh arcsech arccsch arccosech arccoth asin acos atan asec acsc acosec ' +
          'acot asinh acosh atanh asech acsch acosech acoth mod mo abs log ln nsin ncos ntan clamp co',
      }}
      latex={props.latex}
      onChange={props.onChange}
    />
  );
};
