import { Button, Card, Toggle, Text, GeistProvider, Grid, CssBaseline } from '@geist-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEnabled, setParticlesEnabled, setShowBars, setIsGradientMode } from '~/appSlice';
import { AppDispatch, RootState } from "../appSlice";
import DynamicSliders from './DynamicSliders';
import GradientPicker from './GradientSlider';
import ColorPicker from 'react-best-gradient-color-picker'
import { wind } from '~/root';

const LayersComponent = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { enabled, currentVariantIndex, currentSystem, particlesEnabled, showBars, isGradientMode, currentVariant } = useSelector((state: RootState) => state.app);
    const [gradientVersion, setGradientVersion] = useState(0);

    useEffect(() => {
        // Set up an interval to check for changes in wind.gradient
        const intervalId = setInterval(() => {
            setGradientVersion((prev: number) => prev + 1);
        }, 1000); // Check every 100ms

        return () => clearInterval(intervalId);
    }, []);

    // This effect will run whenever gradientVersion changes
    useEffect(() => {
        // Force re-render
    }, [gradientVersion, currentSystem, currentVariantIndex]);

    const handleEnabledChange = useCallback((event: { target: { checked: boolean; }; }) => {
        dispatch(setEnabled(event.target.checked));
    }, [dispatch]);

    const handleParticlesEnabledChange = useCallback((event: { target: { checked: boolean; }; }) => {
        dispatch(setParticlesEnabled(event.target.checked));
    }, [dispatch]);

    const handleBarSpectrumChange = useCallback((event: { target: { checked: boolean; }; }) => {
        dispatch(setShowBars(event.target.checked));
    }, [dispatch]);

    const handleGradientChange = (newGradient: string) => {
        wind.gradient = newGradient;
    };

    const handleGradientModeChange = (event: { target: { checked: boolean; }; }) => {
        dispatch(setIsGradientMode(event.target.checked));
    };

    return (
        <div style={{overflow: 'none'}}>
            <Card margin={0} padding={0} style={{ marginBottom: 10, marginLeft: 0, marginRight: 0, padding: 0 }}>
                <div style={{ display: 'flex', margin: 0, padding: 0 }}>
                    <Text style={{ margin: 0, padding: 0 }} h5>Current System: </Text>
                    <Text style={{ margin: 0, paddingLeft: '20px' }} type='secondary'>{currentSystem?.name}</Text>
                    <Text h5 style={{ margin: 0, paddingLeft: '50px' }}>Current Variant:</Text>
                    <Text style={{ margin: 0, paddingLeft: '20px' }} type='secondary'>{currentVariant?.name + ' • ' + currentVariantIndex}</Text>
                </div>
            </Card >
            <Grid.Container gap={2} style={{ margin: '0px 0px 0px 0px' }}>
                {[
                    { checked: enabled, onChange: handleEnabledChange, label: "Enable" },
                    { checked: particlesEnabled, onChange: handleParticlesEnabledChange, label: "Enable Particles" },
                    { checked: showBars, onChange: handleBarSpectrumChange, label: "Show Bar Spectrum" },
                    { checked: isGradientMode, onChange: handleGradientModeChange, label: "Use Gradient" }
                ].map((item, index) => (
                    <Grid key={index} style={{ marginTop: '-5px' }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <Toggle
                                checked={item.checked}
                                onChange={item.onChange}
                                scale={1.25}
                            />
                            <span style={{ marginLeft: '8px', marginTop: 7 }}>{item.label}</span>
                        </div>
                    </Grid>
                ))}
            </Grid.Container>
            {isGradientMode && (
                <div style={{ margin: '5px 5px 0 0px' }}>
                    <GradientPicker />
                </div>
            )}
            <div style={{ marginTop: 10 }}>
                <DynamicSliders />
            </div>
        </div>
    );
}

export default LayersComponent;