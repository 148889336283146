import React, { useEffect, useRef } from 'react';
import katex from 'katex';

interface KatexComponentProps {
  math: string;
  block?: boolean;
}

const KatexComponent: React.FC<KatexComponentProps> = ({ math, block }) => {
  const containerRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      katex.render(math, containerRef.current, {
        displayMode: block,
      });
    }
  }, [math, block]);

  return <span ref={containerRef} />;
};

export default KatexComponent;
