import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GradientStop, RootState, setMediaReady } from '../appSlice';
import { wind } from "../root";
import fragmentShaderSource from '../shaders/generated/fragment.glsl.js';
import vertexShaderSource from '../shaders/generated/vertex.glsl.js';
import { Level } from '~/audioProcessor';

export interface WebGLCanvasProps {
    mediaSource?: 'webcam' | 'image' | 'video';
    sourceUrl?: string;
}

const WebGLCanvas: React.FC<WebGLCanvasProps> = ({
    mediaSource,
    sourceUrl
}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const mediaRef = useRef<HTMLVideoElement | HTMLImageElement | null>(null);
    const animationFrameRef = useRef<number | null>(null);
    const { mediaReady } = useSelector((state: RootState) => state.app);
    const dispatch = useDispatch();

    const {
        monitorSize,
        enabled,
        particlesEnabled,
        showBars,
        sliderNames,
        functionCodes,
        codeStrings,
        isGradientMode
    } = useSelector((state: RootState) => state.app);

    console.log('canvas rendered')
    const frameRef = useRef<number>(0);
    const [shouldRender, setShouldRender] = useState(enabled);
    const particlesEnabledRef = useRef(particlesEnabled);

    useEffect(() => {
        particlesEnabledRef.current = particlesEnabled;
    }, [particlesEnabled]);

    useEffect(() => {
        setShouldRender(enabled);
    }, [enabled]);

    // Media setup effect
    useEffect(() => {
        console.log(mediaSource, 34214)
        const setupMedia = async () => {
            if (!mediaSource) {
                return;
            }

            if (mediaSource === 'webcam') {
                const video = document.createElement('video');
                video.autoplay = true;
                video.playsInline = true;
                mediaRef.current = video;

                try {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            width: { ideal: monitorSize.width },
                            height: { ideal: monitorSize.height }
                        }
                    });
                    video.srcObject = stream;
                    video.onloadedmetadata = () => {
                        video.play();
                        dispatch(setMediaReady(true));
                    };
                } catch (err) {
                    console.error('Error accessing webcam:', err);
                    dispatch(setMediaReady(false));
                }
            } else if (mediaSource === 'video' && sourceUrl) {
                const video = document.createElement('video');
                video.autoplay = true;
                video.loop = true;
                video.playsInline = true;
                video.crossOrigin = "anonymous";
                video.src = sourceUrl;
                mediaRef.current = video;

                // Add error handling for video
                video.onerror = (e) => {
                    console.error('Error loading video:', video.error);
                    dispatch(setMediaReady(false));
                };

                video.onloadedmetadata = () => {
                    video.play().catch(err => {
                        console.error('Error playing video:', err);
                        dispatch(setMediaReady(false));
                    });
                    dispatch(setMediaReady(true));
                };
            } else if (mediaSource === 'image' && sourceUrl) {
                const image = new Image();
                image.crossOrigin = "anonymous";
                mediaRef.current = image;

                // Add error handling for image
                image.onerror = (e) => {
                    console.error('Error loading image:', e);
                    dispatch(setMediaReady(false));
                };

                image.onload = () => {
                    dispatch(setMediaReady(true));
                };

                image.src = sourceUrl;
            }
        };

        setupMedia();

        return () => {
            if (mediaRef.current instanceof HTMLVideoElement) {
                const video = mediaRef.current;
                const stream = video.srcObject as MediaStream;
                if (stream) {
                    stream.getTracks().forEach(track => track.stop());
                }
                video.srcObject = null;
            }
          //  dispatch(setMediaReady(false));
        };
    }, [mediaSource, sourceUrl]);

    useEffect(() => {
        console.log (342, !shouldRender || (!mediaReady && mediaSource !== undefined), shouldRender, mediaReady, mediaSource !== undefined)
        if (!shouldRender || (!mediaReady && mediaSource !== undefined)) return;

        const canvas = canvasRef.current;
        const gl = canvas?.getContext('webgl', { alpha: true });

        if (!gl) {
            console.error('WebGL not supported');
            return;
        }

        // Generate shader source first
        const generatedFragmentShader = generateShaderSource();

        // Create shaders with error checking
        const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
        const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, generatedFragmentShader);

        // Log shader creation status
        if (!vertexShader || !fragmentShader) {
            console.error('Failed to create shaders:', {
                vertexShader: !!vertexShader,
                fragmentShader: !!fragmentShader,
                fragmentSource: generatedFragmentShader
            });
            return;
        }

        // Create program with null checks
        const shaderProgram = createProgram(gl, vertexShader, fragmentShader);

        if (!shaderProgram) {
            console.error('Failed to create shader program');
            if (vertexShader) gl.deleteShader(vertexShader);
            if (fragmentShader) gl.deleteShader(fragmentShader);
            return;
        }

        // Create and set up texture
        const texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);

        // Set texture parameters
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

        function generateShaderSource() {

            // Start with the base fragment shader
            let shader = fragmentShaderSource;

            // Insert uniform declarations
            const uniformDeclarations = Object.entries(sliderNames)
                ?.map(([key, value]) => value.map(body => `uniform float ${body};`).join('\n'))
                .join('\n\n');

            // Insert function definitions
            const functionDefinitions = functionCodes
                ?.map(([name, body]) => `float ${name} { return ${body}; }`)
                .join('\n');

            // Insert variable declarations and calculations
            const variableDeclarations = codeStrings
                ?.filter(([sym]) => sym !== 'k')
                ?.map(([sym, expr]) => `float ${sym} = ${expr};`)
                .join('\n');

            const coordScaling = codeStrings
                ?.map(([sym, expr]) => {
                    if (sym === 'k') {
                        return `vec2 vis_coord = coord * ${expr};`;
                    }
                    return '';
                })
                .filter(Boolean)
                .join('\n');

            // Insert these into the shader at appropriate locations
            shader = shader.replace('// UNIFORMS_PLACEHOLDER', uniformDeclarations);
            shader = shader.replace('// FUNCTIONS_PLACEHOLDER', functionDefinitions);
            shader = shader.replace('// COORD_SCALE_PLACEHOLDER', coordScaling);
            shader = shader.replace('// VARIABLES_PLACEHOLDER', variableDeclarations);
            return shader;
        }

        function createShader(gl: WebGLRenderingContext, type: number, source: string) {
            const shader = gl.createShader(type);
            if (!shader) {
                console.error('Failed to create shader');
                return null;
            }

            gl.shaderSource(shader, source);
            gl.compileShader(shader);
            console.warn(source)
            if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
                const error = gl.getShaderInfoLog(shader);
                console.error('Shader compilation error:', {
                    shaderType: type === gl.VERTEX_SHADER ? 'VERTEX_SHADER' : 'FRAGMENT_SHADER',
                    error
                });
                gl.deleteShader(shader);
                return null;
            }
            return shader;
        }

        function createProgram(gl: WebGLRenderingContext, vertexShader: WebGLShader | null, fragmentShader: WebGLShader | null) {
            // Early return if either shader is null
            if (!vertexShader || !fragmentShader) {
                console.error('Cannot create program: shaders are null', {
                    vertexShader: !!vertexShader,
                    fragmentShader: !!fragmentShader
                });
                return null;
            }

            const program = gl.createProgram();
            if (!program) {
                console.error('Failed to create WebGL program');
                return null;
            }

            // Attach shaders
            gl.attachShader(program, vertexShader);
            gl.attachShader(program, fragmentShader);

            // Link program
            gl.linkProgram(program);

            // Check if linking succeeded
            if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
                const linkError = gl.getProgramInfoLog(program);
                const vertexShaderLog = gl.getShaderInfoLog(vertexShader);
                const fragmentShaderLog = gl.getShaderInfoLog(fragmentShader);

                gl.validateProgram(program);
                const validationInfo = gl.getProgramInfoLog(program);
                const numAttributes = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES);
                const numUniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);

                console.error('WebGL Program Creation Failed:', {
                    linkError: linkError || 'No linking errors',
                    vertexShaderLog: vertexShaderLog || 'No vertex shader errors',
                    fragmentShaderLog: fragmentShaderLog || 'No fragment shader errors',
                    validationInfo: validationInfo || 'No validation info',
                    programInfo: {
                        activeAttributes: numAttributes,
                        activeUniforms: numUniforms
                    }
                });

                gl.deleteProgram(program);
                return null;
            }

            return program;
        }

        // Set up vertices and texture coordinates
        const vertices = new Float32Array([
            -1.0, -1.0,
            1.0, -1.0,
            -1.0, 1.0,
            1.0, 1.0,
        ]);

        const textureCoords = new Float32Array([
            0.0, 1.0,  // Flipped Y coordinates to match WebGL texture coordinates
            1.0, 1.0,
            0.0, 0.0,
            1.0, 0.0,
        ]);

        const vertexBuffer = gl.createBuffer();
        const textureCoordBuffer = gl.createBuffer();

        gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

        gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, textureCoords, gl.STATIC_DRAW);

        const render = () => {
            if (!gl || !shaderProgram) return;
            const levelGroups: Level[][] = wind.levelGroups;

            gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
            gl.clear(gl.COLOR_BUFFER_BIT);

            // Use shader program
            gl.useProgram(shaderProgram);

            // Set up attributes and uniforms
            const vertexPosition = gl.getAttribLocation(shaderProgram, 'aVertexPosition');
            const textureCoord = gl.getAttribLocation(shaderProgram, 'aTextureCoord');

            const useTextureLocation = gl.getUniformLocation(shaderProgram, 'useTexture');
            const samplerLocation = gl.getUniformLocation(shaderProgram, 'uSampler');

            gl.uniform1i(useTextureLocation, mediaReady ? 1 : 0);
            gl.uniform1i(samplerLocation, 0); // Use texture unit 0
            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_2D, texture);

            if (mediaRef.current) {
                gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, mediaRef.current);
            }

            gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
            gl.vertexAttribPointer(vertexPosition, 2, gl.FLOAT, false, 0, 0);
            gl.enableVertexAttribArray(vertexPosition);

            gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
            gl.vertexAttribPointer(textureCoord, 2, gl.FLOAT, false, 0, 0);
            gl.enableVertexAttribArray(textureCoord);

            // Set existing uniforms from your system
            for (const key in wind.internalFloats) {
                if (Object.prototype.hasOwnProperty.call(wind.internalFloats, key)) {
                    const value = wind.internalFloats[key]!;
                    const uSym = gl.getUniformLocation(shaderProgram, key);
                    if (uSym) {
                        gl.uniform1f(uSym, value);
                    }
                }
            }

            const uFrame = gl.getUniformLocation(shaderProgram, 'uFrame');
            const enableParticles = gl.getUniformLocation(shaderProgram, 'enableParticles');
            const uResolution = gl.getUniformLocation(shaderProgram, 'uResolution');
            const gradientStops = new Float32Array(30);
            const gradientPositions = new Float32Array(10);
            const gradientStopsLocation = gl.getUniformLocation(shaderProgram, 'gradientStops');
            const gradientPositionsLocation = gl.getUniformLocation(shaderProgram, 'gradientPositions');
            const gradientStopCountLocation = gl.getUniformLocation(shaderProgram, 'gradientStopCount');

            function hexToRgb(hex: string) {
                const r = parseInt(hex.slice(1, 3), 16) / 255;
                const g = parseInt(hex.slice(3, 5), 16) / 255;
                const b = parseInt(hex.slice(5, 7), 16) / 255;
                return { r, g, b };
            }

            wind.gradientStops?.forEach((stop: GradientStop, index: number) => {
                const color = hexToRgb(stop.color);
                gradientStops[index * 4] = color.r;
                gradientStops[index * 4 + 1] = color.g;
                gradientStops[index * 4 + 2] = color.b;
                gradientStops[index * 4 + 3] = 1.0; // Alpha
                gradientPositions[index] = stop.position / 100;
            });

            if (levelGroups && levelGroups.length > 0) {
                for (let levelGroup of levelGroups) {
                    if (levelGroup.length == 36) {
                        const levels = levelGroup.map(item => item.value);
                        const uBarsLocation = gl.getUniformLocation(shaderProgram, "uBars");
                        gl.uniform1fv(uBarsLocation, levels);
                    } else {
                        for (let level of levelGroup) {
                            if (level.label !== undefined) {
                                const uSym = gl.getUniformLocation(shaderProgram, level.label);
                                if (uSym !== null) {
                                    gl.uniform1f(uSym, level.value);
                                }

                                const uSymCumulative = gl.getUniformLocation(shaderProgram, `${level.label}_c`);
                                if (uSymCumulative !== null) {
                                    gl.uniform1f(uSymCumulative, level.value);
                                }
                            }
                        }
                    }
                }
            }

            gl.uniform1f(uFrame, frameRef.current);
            gl.uniform1i(enableParticles, particlesEnabledRef.current ? 1 : 0);
            gl.uniform2f(uResolution, monitorSize.width, monitorSize.height);
            gl.uniform4fv(gradientStopsLocation, gradientStops);
            gl.uniform1fv(gradientPositionsLocation, gradientPositions);
            gl.uniform1i(gradientStopCountLocation, wind.gradientStops?.length || 0);

            // Draw
            gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
            frameRef.current += 1;

            // Request next frame
            animationFrameRef.current = requestAnimationFrame(render);
        };

        render();

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            if (gl) {
                gl.deleteProgram(shaderProgram);
                gl.deleteBuffer(vertexBuffer);
                gl.deleteBuffer(textureCoordBuffer);
                gl.deleteTexture(texture);
            }
        };
    }, [shouldRender, mediaReady, codeStrings, showBars, monitorSize, functionCodes, sliderNames, isGradientMode, mediaSource]);

    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            backgroundColor: "black"
        }}>
            <canvas
                ref={canvasRef}
                width={monitorSize.width}
                height={monitorSize.height}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 1,
                }}
            />
        </div>
    );
};

export default WebGLCanvas;