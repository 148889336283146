export interface Range {
    min: number;
    max: number;
    label?: string;
}

export interface Level {
    value: number;
    label?: string;
}

interface DefaultRangeSet {
    min: number;
    max: number;
    numRanges: number;
}

interface RangeSpec {
    rangeInput: RangeInput;
    labels?: string[];
}

type RangeInput = Range[] | DefaultRangeSet;

export class AudioProcessor {
    audioContext: AudioContext;
    analyser: AnalyserNode;
    bufferLength: number;
    dataArray: Uint8Array;
    ranges: Range[][];
    rangeSpecs: RangeSpec[];
    levelGroups: Level[][];
    cumulativeValue: number[];

    constructor(audioContext: AudioContext, analyserNode: AnalyserNode, rangeSpecs: RangeSpec[]) {
        this.audioContext = audioContext;
        this.analyser = analyserNode;
        this.analyser.fftSize = 2048;
        this.bufferLength = this.analyser.frequencyBinCount;
        this.dataArray = new Uint8Array(this.bufferLength);
        this.ranges = [];
        this.rangeSpecs = rangeSpecs;
        this.levelGroups = [];
        this.cumulativeValue = new Array(rangeSpecs.length).fill(0);

        for (let i = 0; i < rangeSpecs.length; i++) {
            if (Array.isArray(rangeSpecs[i]?.rangeInput)) {
                const rangeArray = rangeSpecs[i]!.rangeInput as Range[];
                this.ranges.push(rangeArray);
            } else {
                const defaultRangeSet = rangeSpecs[i]!.rangeInput as DefaultRangeSet;
                const rangeArray = this.generateDefaultRanges(defaultRangeSet.min, defaultRangeSet.max, defaultRangeSet.numRanges);
                this.ranges.push(rangeArray);
            }
        }
    }

    private generateDefaultRanges(minFreq: number, maxFreq: number, numRanges: number): Range[] {
        const step = (maxFreq - minFreq) / numRanges;
        return Array.from({ length: numRanges }, (_, i) => ({
            min: minFreq + i * step,
            max: minFreq + (i + 1) * step,
        } as Range));
    }

    analyze(): Level[][] {
        this.analyser.getByteFrequencyData(this.dataArray);
        this.levelGroups = []

        for (let i = 0; i < this.ranges.length; i++) {
            const rangeGroup = this.ranges[i]!;
            const rangeSpec = this.rangeSpecs[i]!;
            const groupResults: Level[] = [];

            for (let { min, max, label } of rangeGroup) {
                const lowIndex = Math.floor(min / (this.audioContext.sampleRate / this.bufferLength));
                const highIndex = Math.ceil(max / (this.audioContext.sampleRate / this.bufferLength));

                let sum = 0;
                for (let i = lowIndex; i <= highIndex && i < this.bufferLength; i++) {
                    sum += this.dataArray[i]!;
                }

                const value = sum / (highIndex - lowIndex + 1);
                this.cumulativeValue[i]! += Math.pow(value, 2);

                const baseLabel = label ?? (rangeSpec.labels?.[0] || '');

                groupResults.push({
                    value: value,
                    label: baseLabel
                } as Level);

                if (rangeGroup.length != 36) {
                    groupResults.push({
                        value: 0.000005 * this.cumulativeValue[i]!,
                        label: `${baseLabel}_c`
                    } as Level);
                }
            }

            this.levelGroups.push(groupResults);
        }

        return this.levelGroups;
    }
}