// Generated from vertex.glsl
const shader = `attribute vec4 aVertexPosition;
attribute vec2 aTextureCoord;
varying vec2 vTextureCoord;

void main(void){
    gl_Position=aVertexPosition;
    vTextureCoord=aTextureCoord;
}
`;
export default shader;
