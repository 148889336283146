export function mathQuillFix() {
    setTimeout(() => {
        const elements = document.querySelectorAll('.mq-scaled');

        for (let element of Array.from(elements) as HTMLElement[]) {
            element = element as HTMLElement;
            if (element) {
                const matrixValues = window.getComputedStyle(element).transform.replace('matrix(', '').replace(')', '').split(',');

                if (parseFloat(matrixValues[3]!) == 0.0) {
                    element.style.transform = 'matrix(1.1, 0, 0, 1.5, 0, 0)'
                }
            }
        }
    }, 10);
}