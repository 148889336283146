import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Select, Spacer, Text } from "@geist-ui/core";
import { AppDispatch, RootState, loadPresetsAsync, loadVariant, setCurrentSystem, setVariant, loadVariantFromIndex } from "../appSlice";

const PresetsComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { presets, currentSystem, currentVariantIndex } = useSelector((state: RootState) => state.app);
  const [selectedPreset, setSelectedPreset] = useState<string | undefined>();
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number | undefined>(currentVariantIndex);

  useEffect(() => {
    dispatch(loadPresetsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (currentSystem && presets && presets[currentSystem.name]) {
      setSelectedPreset(currentSystem.name);
      setSelectedVariantIndex(0);
    }
  }, [currentSystem, presets]);

  // Sort presets alphabetically
  const sortedPresets = useMemo(() => {
    return presets ? Object.keys(presets).sort() : [];
  }, [presets]);

  const handlePresetChange = (value: string | string[]) => {
    const presetName = value as string;
    setSelectedPreset(presetName);
    dispatch(loadVariantFromIndex({ systemName: presetName, index: 0 }));
    setSelectedVariantIndex(0);
    
    const selectedSystem = presets?.[presetName];
    if (selectedSystem) {
      dispatch(setCurrentSystem(selectedSystem));
      if (selectedSystem.variants.length > 0) {
        dispatch(loadVariant(selectedSystem.variants[0]!));
      }
    } else {
      console.error("Selected preset not found in presets:", presetName);
    }
  };

  const handleVariantSelect = (index: number) => {
    setSelectedVariantIndex(index);
    if (selectedPreset && presets) {
      const variant = presets[selectedPreset]?.variants[index];
      if (variant) {
        dispatch(loadVariant(variant));
      } else {
        console.error("Selected variant not found:", index);
      }
    }
  };

  return (
    <Card>
      <Card.Content>
        <Text h4>Select Preset</Text>
        <Select
          placeholder="Select a Preset"
          value={selectedPreset}
          onChange={handlePresetChange}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {sortedPresets.map((presetName) => (
            <Select.Option key={presetName} value={presetName}>
              {presetName}
            </Select.Option>
          ))}
        </Select>
        <Spacer h={1} />
        {selectedPreset && presets && presets[selectedPreset] && (
          <>
            <Select
              placeholder="Select a Variant"
              value={selectedVariantIndex?.toString()}
              onChange={(val) => handleVariantSelect(parseInt(val as string))}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {presets[selectedPreset].variants.map((variant, index) => (
                <Select.Option key={index} value={index.toString()}>
                  {variant.name || `Variant ${index + 1}`}
                </Select.Option>
              ))}
            </Select>
            <Spacer h={1} />
          </>
        )}
      </Card.Content>
    </Card>
  );
};

export default PresetsComponent;