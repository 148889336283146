import { defaultGLSLExpressions } from "./appSlice";

type EquationCode = [string, string];
type FunctionCode = [string, string];

export interface ProcessMessageResult {
    functionCodes: FunctionCode[];
    equationCodes: EquationCode[];
    sliderFloats: Record<string, string[]>;
}

type ParseResult<T> = {
    success: boolean;
    result: T;
    error: string;
};

let pureScriptParselatexStrToGLSL: (input: string) => (v: boolean) => ParseResult<string>;
let pureScriptParselatexStrToTree: (input: string) => (v: boolean) => ParseResult<any>;
let pureScriptParselatexStrToTreeStr: (input: string) => (v: boolean) => ParseResult<string>;
type ASTTree =
    | { value0: string, value1: string, value2: ASTTree[] }
    | { value0: string, value1: string  };

const outputSymbolStrs = Object.keys(defaultGLSLExpressions);
const outputRhs = Object.values(defaultGLSLExpressions);
const inputSymbolStrs = ['x', 'y', 'z', 'l', 'c', 'n', 'm', 't', 'l_c', 'm_c', 't_c', 'q', 'b', 'm', 't', 'g', 'b_c', 'm_c', 't_c', 'g_c', 'i'];
const customSymStrs = ['r', 'e', 'pi', 'theta', 'psi'];

function convertFunctionSignature(signature: string): string | null {
    const pattern = /(.+)\(([^)]*)\)/;
    const match = signature.match(pattern);
    if (match) {
        const functionName = match[1];
        const parameters = match[2]!.split(',');
        const convertedParameters = parameters.map((param: string) => `float ${param.trim()}`);
        return `${functionName}(${convertedParameters.join(', ')})`;
    }
    return null;
}

async function loadPureScriptModule() {
    if (!pureScriptParselatexStrToGLSL) {
        const module = await import('./purescript/MathProcessor/index.js');
        pureScriptParselatexStrToGLSL = module.parseLatexToGLSL;
        pureScriptParselatexStrToTree = module.parseLatexToTree;
        pureScriptParselatexStrToTreeStr = module.parseLatexToTreeStr;
    }
}

const replaceInverseTrig = (input: string): string => {
    return input.replace(/\\([a-zA-Z]+)\^{-1}/g, (match, funcName) => `\\arc${funcName}`);
};

function isTreeNode(tree: ASTTree): tree is { value0: string, value1: string, value2: ASTTree[] } {
    return tree.value0 === 'TreeNode';
}

function isTreeLeaf(tree: ASTTree): tree is { value0: string, value1: string } {
    return tree.value0 === 'TreeLeaf';
}

function findVariableNodes(tree: ASTTree): string[] {
    if (isTreeLeaf(tree)) {
        if (isNaN(parseFloat(tree.value1))) {
            return [tree.value1];
        }
        return [];
    } else if (isTreeNode(tree)) {
        return (tree as { value2: ASTTree[] }).value2.flatMap(findVariableNodes);
    }
    return [];
}

export async function processMessage(data: Record<string, string>): Promise<ProcessMessageResult> {
    const equationCodesRecord: Record<string, string> = {};
    const functionCodes: FunctionCode[] = [];
    const sliderFloats: Record<string, string[]> = {};
    const dependencyGraph: Record<string, string[]> = {};

    for (let latex of Object.entries(data)) {
        const [lhs, rhs] = latex;
        let latexStr = lhs + '=' + rhs;
        latexStr = replaceInverseTrig(latexStr);
        const isFirst = latexStr === 'h=x-\\sin\\left(y-g_c\\right)';

        try {
            await loadPureScriptModule();
            const glslExpr = pureScriptParselatexStrToGLSL(latexStr)(isFirst);
            const treeResult = pureScriptParselatexStrToTree(latexStr)(false);
            const treeStr = pureScriptParselatexStrToTreeStr(latexStr)(false);
            console.log("latexStr in: ", latexStr, "\nGLSL out: ", glslExpr, "\nTree out: ", treeResult, "\nTree str out: ", treeStr);

            if (glslExpr.success && glslExpr.result) {
                const exprValue = glslExpr.result;

                let [outLhs, outRhs] = exprValue.split('=').map((s: string) => s.trim());
                [outLhs, outRhs] = [outLhs!, outRhs!];

                if (outLhs.includes('(') && outLhs.includes(')')) {
                    // It's a function
                    const signature = convertFunctionSignature(outLhs);
                    if (signature) {
                        functionCodes.push([signature, outRhs]);
                    }
                } else {
                    equationCodesRecord[outLhs] = outRhs;
                }

                // Find dependencies using the AST
                if (treeResult.success && treeResult.result) {
                    const tree = treeResult.result;
                    // Assuming the right-hand side is the second child of the root node
                    const dependencies = isTreeNode(tree) && tree.value2.length > 1
                        ? findVariableNodes(tree.value2[1]!)
                        : [];

                    dependencyGraph[outLhs] = dependencies;

                    // Find undefined symbols (potential slider floats)
                    const undefinedSymbols = dependencies.filter((sym: string) =>
                        !outputSymbolStrs.includes(sym) &&
                        !inputSymbolStrs.includes(sym) &&
                        !customSymStrs.includes(sym) &&
                        !outputRhs.includes(sym)
                    );

                    if (undefinedSymbols.length > 0) {
                        sliderFloats[latexStr] = Array.from(new Set(undefinedSymbols));
                    }
                }
            } else {
                console.error("Error parsing latexStr:", glslExpr.error);
            }
        } catch (error) {
            console.error(`Error processing latexStr: ${latexStr}`, error);
        }
    }

    // Topological sorting
    console.log('dependencyGraph: ', dependencyGraph)
    const visited: Record<string, boolean> = {};
    const sortedEquations: EquationCode[] = [];

    function visit(node: string) {
        if (visited[node]) return;
        visited[node] = true;

        const dependencies = dependencyGraph[node] || [];
        for (const dep of dependencies) {
            if (dep in equationCodesRecord) {
                visit(dep);
            }
        }

        sortedEquations.push([node, equationCodesRecord[node]!]);
    }

    for (const node of Object.keys(equationCodesRecord)) {
        visit(node);
    }

    const declaredVariables = new Set(Object.keys(equationCodesRecord));

    for (const latexStr in sliderFloats) {
        sliderFloats[latexStr] = sliderFloats[latexStr]!.filter(sym => !declaredVariables.has(sym));
        if (sliderFloats[latexStr].length === 0) {
            delete sliderFloats[latexStr];
        }
    }

    console.log('final sliderFloats: ', sliderFloats)
    return { functionCodes, equationCodes: sortedEquations, sliderFloats };
}