import { Button, Card, Divider, Grid, Input, Text, Toggle } from '@geist-ui/core';
import { Plus } from '@geist-ui/icons';
import 'inter-ui/inter.css';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KatexComponent from "~/components/KatexComponent";
import { setInternalFloat } from '~/windowVarHandler';
import { defaultLatexExpressions, RootState, setImageUrl, setLatexStringsFromCustom } from '../appSlice';
import { wind } from "../root";
import { MathQuillComponent } from './MathQuillComponent';

const DynamicSliders = () => {
    const dispatch = useDispatch();
    const { sliderNames, customLatexStrings, latexStringsRecord, imageUrl } = useSelector((state: RootState) => state.app);
    const [isClient, setIsClient] = useState(false);
    const [internalFloatsVersion, setInternalFloatsVersion] = useState(0);
    const seenSliderNames: string[] = [];

    useEffect(() => {
        setIsClient(true);
    }, []);

    console.log('internalFloatsVersion: ', internalFloatsVersion)

    useEffect(() => {
        // Force re-render
    }, [internalFloatsVersion]);

    const handleChange = useCallback((name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(event.target.value);
        setInternalFloat({ name, value: newValue });
        setInternalFloatsVersion(prev => prev + 1);
    }, []);

    const handleSwitchChange = useCallback((name: any) => (event: any) => {
        setInternalFloat({ name, value: event.target.checked ? 1.0 : 0.0 });
        setInternalFloatsVersion(prev => prev + 1);
    }, []);

    const handleAddClick = () => {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const usedLetters = new Set(customLatexStrings.map(latex => latex.split('=')[0]));

        let nextLetter = '§_§';
        for (const letter of alphabet) {
            if (!usedLetters.has(letter)) {
                nextLetter = letter;
                break;
            }
        }

        if (nextLetter === '§_§') {
            for (const letter of alphabet) {
                const mVar = letter + "_" + customLatexStrings.length;
                if (!usedLetters.has(mVar)) {
                    nextLetter = mVar;
                    break;
                }
            }
        }

        dispatch(setLatexStringsFromCustom([...customLatexStrings, `${nextLetter}=1`]));
    };

    const handleLatexChange = (mathField: any, index: number) => {
        let newLatex = mathField.latex();
        const newList = [...customLatexStrings];
        newList[index] = newLatex;

        dispatch(setLatexStringsFromCustom(newList));
    };

    return (
        <>
            {customLatexStrings.map((latex, index) => {
                let [lhs, rhs] = latex.split("=");
                if (!lhs) lhs = ''
                if (!rhs) rhs = ''

                return (defaultLatexExpressions[lhs] !== rhs) && (
                    <div key={index} className="mathquill-container" style={{ padding: 0, margin: 0, height: "100%" }}>
                        <Card shadow style={{
                            padding: "0px 0px 0px 15px",
                            marginLeft: "-22px",
                            marginRight: "30px",
                            marginTop: "10px",
                            marginBottom: "5px",
                            width: "100%",
                            height: "100%",
                            zIndex: 10,
                        }}>
                            <MathQuillComponent
                                latex={latex}
                                onChange={(mathField: any) => { handleLatexChange(mathField, index) }}
                            />
                            {latex in sliderNames && sliderNames[latex]!.map((name, subIndex) => {
                                if (!seenSliderNames.includes(name) && wind && wind.internalFloats && wind.internalFloats[name]) {
                                    seenSliderNames.push(name);
                                    return (
                                        <div>
                                            <Divider />
                                            <Grid.Container justify='space-between' alignItems='center' style={{ 'paddingLeft': '10px', 'margin': 0, 'paddingTop': 10 }}>
                                                <Grid xs={1.5}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <KatexComponent math={name} />
                                                    </span>
                                                </Grid>
                                                {
                                                    (name.endsWith("_B")) ? <>
                                                        <Grid xs style={{ 'marginLeft': 20 }}>
                                                            <div style={{ margin: '0px 5px 0 5px', display: 'flex', alignItems: 'center' }}>
                                                                <Toggle
                                                                    checked={Math.floor(wind.internalFloats[name]!) == 1}
                                                                    onChange={handleSwitchChange(name)}
                                                                    scale={1.25}
                                                                />
                                                            </div>
                                                        </Grid>
                                                        <span style={{ fontSize: 15 }}>
                                                            <KatexComponent math={'=' + Math.floor(wind.internalFloats[name]!)} />
                                                        </span>
                                                    </> : <>
                                                        <Grid xs={18}>
                                                            <input
                                                                type="range"
                                                                style={{
                                                                    width: '100%',
                                                                    padding: '10px 0',
                                                                    margin: 0,
                                                                    appearance: 'none',
                                                                    background: 'transparent',
                                                                    outline: 'none',
                                                                }}
                                                                value={wind.internalFloats[name]}
                                                                min={-1}
                                                                max={4}
                                                                step={0.000000000000001}
                                                                onChange={handleChange(name)}
                                                                className='custom-range'
                                                            />
                                                        </Grid>
                                                        <Grid xs style={{ 'marginLeft': 20 }}>
                                                            <Input
                                                                value={wind.internalFloats[name]!}
                                                                onChange={() => { handleChange(name) }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined}>
                                                            </Input>
                                                        </Grid>
                                                    </>}
                                            </Grid.Container>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </Card>
                    </div>
                );
            })}
            <Button
                type="success"
                style={{ margin: '20px 0px 10px 10px' }}
                onClick={handleAddClick}
                auto scale={2.5 / 3}
                icon={<Plus />} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                Add
            </Button>
            <div style={{ marginTop: '15px', marginBottom: '100px', marginLeft: '15px', marginRight: '15px' }}>
                <Text mt={1} style={{ fontSize: '12px', margin: 0 }}>
                    Using default values:
                </Text>
                <span style={{ fontSize: 12 }}>
                    <KatexComponent math={Object.entries(latexStringsRecord).filter((entry, index) => {
                        const [lhs, rhs] = entry;
                        return lhs && defaultLatexExpressions[lhs] === rhs
                    }).map((entry, index) => {
                        const [lhs, rhs] = entry;
                        return `${lhs} = ${rhs}`
                    }).join('\\text{, }\\text{ }')}
                    />
                </span>
            </div>
            <div style={{ marginTop: '15px', marginBottom: '100px', marginLeft: '15px', marginRight: '15px' }}>
                <Text mt={1} style={{ fontSize: '12px'}}>
                    Image URL:
                </Text>
                <Input
                    width="100%"
                    value={imageUrl}
                    placeholder="Enter image URL"
                    onChange={(e) => dispatch(setImageUrl(e.target.value))}
                    crossOrigin={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
            </div>
        </>
    );
};

export default DynamicSliders;