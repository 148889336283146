import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid, Select, Text } from '@geist-ui/core';
import { wind } from "../root";
import { AudioProcessor } from "../audioProcessor";
import { RefreshCcw } from '@geist-ui/react-icons';

const InputSelector = () => {
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('default');
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const analyzerRef = useRef<AudioProcessor | null>(null);
    const animationRef = useRef<any>(null);

    const getDevices = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            const deviceInfos = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = deviceInfos.filter(device => device.kind === 'audioinput');
    
            let allDevices = audioDevices;
    
            // Add a display media option only when wind?.env?.electron is true
            if (wind?.env?.electron) {
                const displayMediaOption = {
                    deviceId: 'system_audio',
                    kind: 'audioinput',
                    label: 'System Audio',
                    groupId: ''
                };
                allDevices = [displayMediaOption as MediaDeviceInfo, ...audioDevices];
            }
    
            setDevices(allDevices);
    
            if (allDevices.length > 0) {
                setSelectedDeviceId(allDevices[0]!.deviceId);
            }
        } catch (err) {
            console.error('Error enumerating devices:', err);
        }
    };

    const handleDeviceChange = async (value: string | string[]) => {
        if (isAnalyzing) {
            await handleStopInput();
        }
        setSelectedDeviceId(value as string);
        setIsAnalyzing(false);
    };

    const handleStartInput = async () => {
        if (selectedDeviceId) {
            let stream;

            if (selectedDeviceId === 'system_audio' && wind?.env?.electron) {
                stream = await navigator.mediaDevices.getDisplayMedia({ audio: true });
            } else {
                stream = await navigator.mediaDevices.getUserMedia({
                    audio: { deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined }
                });
            }

            const audioContext = new (window.AudioContext || wind.webkitAudioContext)();
            const source = audioContext.createMediaStreamSource(stream);
            const analyserNode = audioContext.createAnalyser()!;
            source.connect(analyserNode);

            analyzerRef.current = new AudioProcessor(audioContext, analyserNode, [
                {
                    rangeInput: [
                        { min: 20, max: 250, label: 'b' },
                        { min: 250, max: 4000, label: 'm' },
                        { min: 4000, max: 25000, label: 't' },
                    ]
                },
                { rangeInput: { min: 20, max: 25000, numRanges: 36 } },
                { rangeInput: { min: 40, max: 10000, numRanges: 1 }, labels: ['g'] },
            ]);
            setIsAnalyzing(true);
            updateVisualization();
        }
    };

    const handleStopInput = async () => {
        if (analyzerRef.current) {
            analyzerRef.current = null;
            setIsAnalyzing(false);
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
                animationRef.current = null;
            }
        }
    };

    const updateVisualization = () => {
        if (analyzerRef.current) {
            wind.levelGroups = analyzerRef.current.analyze();
        }
        animationRef.current = requestAnimationFrame(updateVisualization);
    };

    useEffect(() => {
        getDevices();
        return () => {
            handleStopInput();
        };
    }, []);

    return (
        <>
            <Text h4>Select Input:</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Select
                    id="mic-select"
                    value={selectedDeviceId || ''}
                    onChange={handleDeviceChange}
                    style={{ flexGrow: 2, height: "100%" }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                >
                    {devices.map(device => (
                        <Select.Option key={device.deviceId} value={device.deviceId}>
                            {device.label || 'Unnamed Input'}
                        </Select.Option>
                    ))}
                </Select>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
                    <Button
                        icon={<RefreshCcw />}
                        auto
                        onClick={getDevices}
                        style={{ marginRight: 15 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <Button
                        auto
                        style={{ width: 120 }}
                        type="success"
                        onClick={handleStartInput}
                        disabled={isAnalyzing} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    >
                        {isAnalyzing ? 'Listening ...' : 'Start'}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default InputSelector;
