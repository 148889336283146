import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, useTheme, useToasts } from '@geist-ui/core';
import { Plus, Trash } from '@geist-ui/icons';
import { GradientStop } from '~/appSlice';
import { wind } from '~/root';
import { useSelector } from 'react-redux';
import { RootState } from '~/appSlice';

declare global {
  interface Window {
    gradientStops: GradientStop[];
  }
}

const GradientSlider: React.FC = () => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const { setToast, removeAll } = useToasts();
  const [stops, setStops] = useState<GradientStop[]>([]);
  const { isDarkTheme } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.gradientStops = window.gradientStops || [
        { id: '1', color: '#ff0000', position: 0 },
        { id: '2', color: '#0000ff', position: 100 },
      ];
      setStops(window.gradientStops);
    }

    const intervalId = setInterval(() => {
      if (typeof window !== 'undefined' && window.gradientStops) {
        setStops([...window.gradientStops]);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  const updateWindowStops = (newStops: GradientStop[]) => {
    if (typeof window !== 'undefined') {
      window.gradientStops = newStops;
      setStops(newStops);
    }
  };

  const addStop = () => {
    if (sliderRef.current) {
      const newStops = [
        ...stops,
        {
          id: Date.now().toString(),
          color: '#ffffff',
          position: 50,
        },
      ];
      updateWindowStops(newStops);
    }
  };

  const updateStop = (id: string, newPosition: number, newColor?: string) => {
    const newStops = stops.map((stop) =>
      stop.id === id
        ? { ...stop, position: Math.max(0, Math.min(100, newPosition)), ...(newColor && { color: newColor }) }
        : stop
    );
    updateWindowStops(newStops);
  };

  const removeStop = (id: string) => {
    if (stops.length > 2) {
      const newStops = stops.filter((stop) => stop.id !== id);
      updateWindowStops(newStops);
    } else {
      removeAll();
      setToast({
        text: 'Must have at least two stops.',
        type: 'error',
      });
    }
  };

  const handleStopDrag = (id: string, e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const startX = e.clientX;
    const startPosition = stops.find((stop) => stop.id === id)?.position || 0;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (sliderRef.current) {
        const rect = sliderRef.current.getBoundingClientRect();
        const newPosition = startPosition + ((moveEvent.clientX - startX) / rect.width) * 100;
        updateStop(id, newPosition);
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className='my-component-reset'>
      <div className="p-5">
        <div>
          <div
            ref={sliderRef}
            className="h-8 rounded-md cursor-pointer relative"
            style={{
              backgroundImage: `linear-gradient(to right, ${wind.gradientStops?.sort((a: GradientStop, b: GradientStop) =>
                a.position - b.position)?.map((stop: GradientStop) =>
                  `${stop.color} ${stop.position}%`)?.join(', ')})`,
            }}
          >
            {wind.gradientStops?.map((stop: GradientStop) => (
              <div
                key={stop.id}
                className="absolute mt-4 transform -translate-x-1/2"
                style={{ left: `${stop.position}%`, top: 45, boxShadow: `0 -2px 20px 4px ${isDarkTheme ? 'white' : 'black'}` }}
              >
                <div
                  className="relative w-6 cursor-move"
                  onMouseDown={(e) => handleStopDrag(stop.id, e)}
                  style={{ boxShadow: `0 -7px 30px 1px ${isDarkTheme ? 'white' : 'black'}`, backgroundColor: 'transparent', height: '100%' }}
                >
                  <div className="absolute inset-0 h-3" style={{ backgroundColor: stop.color }}></div>
                  <div
                    className="absolute -top-3 left-1/2 w-5 h-5 transform -translate-x-1/2 rotate-45 rounded-md"
                    style={{ backgroundColor: stop.color }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-2" style={{ marginTop: 60 }}>
          <label className="block text-sm font-medium text-gray-700">Gradient Stops:</label>
          {wind.gradientStops?.sort((a: GradientStop, b: GradientStop) => a.position - b.position)?.map((stop: GradientStop) => (
            <div key={stop.id} className="flex items-center justify-between mt-2">
              <div className="flex items-center space-x-2">
                <input
                  type="color"
                  value={stop.color}
                  onChange={(e) => updateStop(stop.id, stop.position)}
                  className="h-8 w-8"
                />
                <Input
                  htmlType="number"
                  width={5}
                  value={stop.position.toString()}
                  onChange={(e) => updateStop(stop.id, Number(e.target.value))}
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
                <span>{stop.color}</span>
              </div>
              <Button
                style={{ backgroundColor: 'transparent' }}
                onClick={() => removeStop(stop.id)}
                auto
                scale={2 / 3}
                icon={<Trash />}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                Delete
              </Button>
            </div>
          ))
          }
          <Button
            style={{ margin: '20px 10px 10px 10px', backgroundColor: 'transparent' }}
            onClick={addStop}
            auto scale={2 / 3}
            icon={<Plus />} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GradientSlider;

function setToast(arg0: { text: string; type: any; }) {
  throw new Error('Function not implemented.');
}
