// Generated from fragment.glsl
const shader = `precision highp float;
#define TWO_PI 6.283185

#define ANIMATION_SPEED 1.5
#define MOVEMENT_SPEED 1.
#define MOVEMENT_DIRECTION vec2(.7,-1.)

#define PARTICLE_SIZE.005

#define PARTICLE_SCALE vec2(.9,.9)
#define PARTICLE_SCALE_VAR vec2(.25,.2)

#define PARTICLE_BLOOM_SCALE vec2(1.,1.)
#define PARTICLE_BLOOM_SCALE_VAR vec2(.3,.1)

#define SPARK_COLOR vec3(1.,1.,1.)*1.5
#define BLOOM_COLOR vec3(1.,1.,1.)*.8
#define SMOKE_COLOR vec4(1.,1.,1.,1.)*.8

#define SIZE_MOD 1.2
#define ALPHA_MOD.9
#define LAYERS_COUNT 40
#define MAX_GRADIENT_STOPS 10

const float pi=3.14159265358979323846;
const float e=2.718281828459045;
const float NOISE_STRENGTH=2.;
const float EPSILON=1e-2;
const float MIN_DENOMINATOR=1e-2;

// Add texture uniforms and varyings
uniform bool useTexture;
uniform sampler2D uSampler;
varying vec2 vTextureCoord;

uniform bool enableParticles;
uniform float uFrame;
uniform float b;
uniform float m;
uniform float t;
uniform float g;
uniform float b_c;
uniform float m_c;
uniform float t_c;
uniform float g_c;
uniform vec2 uResolution;
uniform vec4 gradientStops[MAX_GRADIENT_STOPS];
uniform float gradientPositions[MAX_GRADIENT_STOPS];
uniform int gradientStopCount;
uniform bool showBars;
const int barnum=24;
uniform float uBars[barnum];

// Add RGB to HSV conversion function
vec3 rgb2hsv(vec3 c){
    vec4 K=vec4(0.,-1./3.,2./3.,-1.);
    vec4 p=mix(vec4(c.bg,K.wz),vec4(c.gb,K.xy),step(c.b,c.g));
    vec4 q=mix(vec4(p.xyw,c.r),vec4(c.r,p.yzx),step(p.x,c.r));
    
    float d=q.x-min(q.w,q.y);
    float e=1.e-10;
    // Consider adding this check:
    float h=(d<e)?0.:abs(q.z+(q.w-q.y)/(6.*d+e));
    float s=(q.x<e)?0.:d/(q.x+e);
    return vec3(h,s,q.x);
}

// Add HSV to RGB conversion function
vec3 hsv2rgb(vec3 c)
{
    vec4 K=vec4(1.,2./3.,1./3.,3.);
    vec3 p=abs(fract(c.xxx+K.xyz)*6.-K.www);
    return c.z*mix(K.xxx,clamp(p-K.xxx,0.,1.),c.y);
}

float hash1_2(in vec2 x)
{
    return fract(sin(dot(x,vec2(52.127,61.2871)))*521.582);
}

vec2 hash2_2(in vec2 x)
{
    return fract(sin(x*mat2(20.52,24.1994,70.291,80.171))*492.194);
}

//Simple interpolated noise
vec2 noise2_2(vec2 uv)
{
    //vec2 f = fract(uv);
    vec2 f=smoothstep(0.,1.,fract(uv));
    
    vec2 uv00=floor(uv);
    vec2 uv01=uv00+vec2(0,1);
    vec2 uv10=uv00+vec2(1,0);
    vec2 uv11=uv00+1.;
    vec2 v00=hash2_2(uv00);
    vec2 v01=hash2_2(uv01);
    vec2 v10=hash2_2(uv10);
    vec2 v11=hash2_2(uv11);
    
    vec2 v0=mix(v00,v01,f.y);
    vec2 v1=mix(v10,v11,f.y);
    vec2 v=mix(v0,v1,f.x);
    
    return v;
}

//Simple interpolated noise
float noise1_2(in vec2 uv)
{
    vec2 f=fract(uv);
    //vec2 f = smoothstep(0.0, 1.0, fract(uv));
    
    vec2 uv00=floor(uv);
    vec2 uv01=uv00+vec2(0,1);
    vec2 uv10=uv00+vec2(1,0);
    vec2 uv11=uv00+1.;
    
    float v00=hash1_2(uv00);
    float v01=hash1_2(uv01);
    float v10=hash1_2(uv10);
    float v11=hash1_2(uv11);
    
    float v0=mix(v00,v01,f.y);
    float v1=mix(v10,v11,f.y);
    float v=mix(v0,v1,f.x);
    
    return v;
}

float layeredNoise1_2(in vec2 uv,in float sizeMod,in float alphaMod,in int layers,in float animation,in float z)
{
    float noise=0.;
    float alpha=1.;
    float size=1.;
    vec2 offset;
    for(int i=0;i<LAYERS_COUNT;i++)
    {
        if(i>layers)break;
        offset+=hash2_2(vec2(alpha,size))*10.;
        
        //Adding noise with movement
        noise+=noise1_2(uv*size+z*animation*8.*MOVEMENT_DIRECTION*MOVEMENT_SPEED+offset)*alpha;
        alpha*=alphaMod;
        size*=sizeMod;
    }
    
    noise*=(1.-alphaMod)/(1.-pow(alphaMod,float(layers)));
    return noise;
}

//Rotates point around 0,0
vec2 rotate(in vec2 point,in float deg)
{
    float s=sin(deg);
    float c=cos(deg);
    return mat2(s,c,-c,s)*point;
}

//Cell center from point on the grid
vec2 voronoiPointFromRoot(in vec2 root,in float deg)
{
    vec2 point=hash2_2(root)-.5;
    float s=sin(deg);
    float c=cos(deg);
    point=mat2(s,c,-c,s)*point*.66;
    point+=root+.5;
    return point;
}

//Voronoi cell point rotation degrees
float degFromRootUV(in vec2 uv,in float z)
{
    return z*ANIMATION_SPEED*(hash1_2(uv)-.5)*2.;
}

vec2 randomAround2_2(in vec2 point,in vec2 range,in vec2 uv)
{
    return point+(hash2_2(uv)-.5)*range;
}

vec3 fireParticles(in vec2 uv,in vec2 originalUV,in float z)
{
    vec3 particles=vec3(0.);
    vec2 rootUV=floor(uv);
    float deg=degFromRootUV(rootUV,z);
    vec2 pointUV=voronoiPointFromRoot(rootUV,deg);
    float dist=2.;
    float distBloom=0.;
    
    //UV manipulation for the faster particle movement
    vec2 tempUV=uv+(noise2_2(uv*2.)-.5)*.1;
    tempUV+=-(noise2_2(uv*3.+z)-.5)*.07;
    
    //Sparks sdf
    dist=length(rotate(tempUV-pointUV,.7)*randomAround2_2(PARTICLE_SCALE,PARTICLE_SCALE_VAR,rootUV));
    
    //Bloom sdf
    distBloom=length(rotate(tempUV-pointUV,.7)*randomAround2_2(PARTICLE_BLOOM_SCALE,PARTICLE_BLOOM_SCALE_VAR,rootUV));
    
    //Add sparks
    particles+=(1.-smoothstep(PARTICLE_SIZE*.6,PARTICLE_SIZE*3.,dist))*SPARK_COLOR;
    
    //Add bloom
    particles+=pow((1.-smoothstep(0.,PARTICLE_SIZE*6.,distBloom))*1.,3.)*BLOOM_COLOR;
    
    //Upper disappear curve randomization
    float border=(hash1_2(rootUV)-.5)*2.;
    float disappear=1.-smoothstep(border,border+.5,originalUV.y);
    
    //Lower appear curve randomization
    border=(hash1_2(rootUV+.214)-1.8)*.7;
    float appear=smoothstep(border,border+.4,originalUV.y);
    
    return particles*disappear*appear;
}

//Layering particles to imitate 3D view
vec3 layeredParticles(in vec2 uv,in float sizeMod,in float alphaMod,in int layers,in float smoke,in float z)
{
    vec3 particles=vec3(0);
    float size=1.;
    float alpha=1.;
    vec2 offset=vec2(0.);
    vec2 noiseOffset;
    vec2 bokehUV;
    
    for(int i=0;i<LAYERS_COUNT;i++)
    {
        if(i>layers)break;
        //Particle noise movement
        noiseOffset=(noise2_2(uv*size*2.+.5)-.5)*.15;
        
        //UV with applied movement
        bokehUV=(uv*size+z*MOVEMENT_DIRECTION*MOVEMENT_SPEED)+offset+noiseOffset;
        
        //Adding particles								if there is more smoke, remove smaller particles
        particles+=fireParticles(bokehUV,uv,z)*alpha*(1.-smoothstep(0.,1.,smoke)*(float(i)/float(layers)));
        
        //Moving uv origin to avoid generating the same particles
        offset+=hash2_2(vec2(alpha,alpha))*10.;
        
        alpha*=alphaMod;
        size*=sizeMod;
    }
    
    return particles;
}

// UNIFORMS_PLACEHOLDER

float modFloat(float value,float modulus){
    return value-modulus*floor(value/modulus);
}

float map(vec3 p){
    vec3 n=vec3(0,1,0);
    float k1=5.;
    float k2=(sin(p.x*k1)+sin(p.z*k1))*.8;
    float k3=(sin(p.y*k1)+sin(p.z*k1))*.8;
    float w1=4.-dot(abs(p),normalize(n))+k2;
    float w2=4.-dot(abs(p),normalize(n.yzx))+k3;
    float s1=length(mod(p.xy+vec2(sin((p.z+p.x)*2.)*.3,cos((p.z+p.x)*1.)*.5),2.)-1.)-.2;
    float s2=length(mod(.5+p.yz+vec2(sin((p.z+p.x)*2.)*.3,cos((p.z+p.x)*1.)*.3),2.)-1.)-.2;
    return min(w1,min(w2,min(s1,s2)));
}

vec2 rot(vec2 p,float a){
    return vec2(
        p.x*cos(a)-p.y*sin(a),
        p.x*sin(a)+p.y*cos(a)
    );
}

vec4 renderBar(vec2 coord){
    coord/=2.;
    float totalBars=float(barnum);
    float gap=.02;// Gap between bars
    float barWidth=((1./totalBars))*4.2;// Width of each bar
    
    // Calculate the total width of all bars plus gaps
    float totalWidth=totalBars*(barWidth+gap)-gap;
    
    // Shift the bars to be centered
    float startX=-totalWidth/2.;
    
    float barHeight=0.;
    for(int i=0;i<barnum;i++){
        float barStartX=startX+float(i)*(barWidth+gap);// Calculate the starting position for each bar
        float barEndX=barStartX+barWidth;
        
        if(coord.x>=barStartX&&coord.x<barEndX){
            barHeight=uBars[i]/50.;
            break;
        }
    }
    
    barHeight=clamp(barHeight,0.,2.3);
    
    // Shift the bar position to start from y = -30.0
    float bottomY=-500./uResolution.y;
    if(coord.y<=barHeight+bottomY&&coord.y>bottomY){
        return vec4(1.,1.,1.,1.);// White bar with full opacity
    }else{
        return vec4(0.,1.,1.,0.);// Transparent color outside the bar
    }
}

vec4 renderBarShadow(vec2 coord,float offsetX){
    // Apply the x-offset to shift the shadow horizontally
    coord.x-=offsetX;
    
    coord/=2.;
    float totalBars=float(barnum);
    float gap=.02;// Gap between bars
    float barWidth=((1./totalBars))*4.2;// Width of each bar
    
    // Calculate the total width of all bars plus gaps
    float totalWidth=totalBars*(barWidth+gap)-gap;
    
    // Shift the bars to be centered
    float startX=-totalWidth/2.;
    
    float barHeight=0.;
    for(int i=0;i<barnum;i++){
        float barStartX=startX+float(i)*(barWidth+gap);// Calculate the starting position for each bar
        float barEndX=barStartX+barWidth;
        
        if(coord.x>=barStartX&&coord.x<barEndX){
            barHeight=uBars[i]/50.;
            break;
        }
    }
    
    barHeight=clamp(barHeight,0.,2.3);
    
    // Shift the bar position to start from y = -30.0
    float bottomY=-500./uResolution.y;
    if(coord.y<=barHeight+bottomY&&coord.y>bottomY){
        return vec4(.2,.2,.2,.5);// Translucent grey shadow
    }else{
        return vec4(0.,0.,0.,0.);// Transparent color outside the bar
    }
}

float log(float x,float base){
    return log(x)/log(base);
}

float hash(float n){
    return fract(sin(n)*43758.5453123);
}

float noise(float x){
    float i=floor(x);
    float f=fract(x);
    float u=f*f*(3.-2.*f);// Smooth interpolation
    return mix(hash(i),hash(i+1.),u);
}

float fbm(float x){
    float v=0.;
    float a=.5;
    float shift=100.;
    for(int i=0;i<5;++i){
        v+=a*noise(x);
        x=x*2.+shift;
        a*=.5;
    }
    return v;
}

float nsin(float x){
    return sin(x+fbm(x)*NOISE_STRENGTH);
}

float ncos(float x){
    return cos(x+fbm(x)*NOISE_STRENGTH);
}

float ntan(float x){
    return tan(x+fbm(x)*NOISE_STRENGTH);
}

float sinh(float x){
    return(exp(x)-exp(-x))/2.;
}

float cosh(float x){
    return(exp(x)+exp(-x))/2.;
}

float tanh(float x){
    return sinh(x)/cosh(x);
}

float coth(float x){
    return 1./tanh(x);
}

float sech(float x){
    return 1./cosh(x);
}

float csch(float x){
    return 1./sinh(x);
}

float asinh(float x){
    return log(x+sqrt(x*x+1.));
}

float acosh(float x){
    return log(x+sqrt(x*x-1.));
}

float atanh(float x){
    return.5*log((1.+x)/(1.-x));
}

float acoth(float x){
    return.5*log((x+1.)/(x-1.));
}

float asech(float x){
    return acosh(1./x);
}

float acsch(float x){
    return asinh(1./x);
}

float arcsinh(float x){
    return log(x+sqrt(x*x+1.));
}

float arccosh(float x){
    return log(x+sqrt(x*x-1.));
}

float arctanh(float x){
    if(abs(x)>=1.){
        return 0.;// arctanh is only defined for |x| < 1
    }
    return.5*log((1.+x)/(1.-x));
}

float arccoth(float x){
    if(abs(x)<=1.){
        return 0.;// arccoth is only defined for |x| > 1
    }
    return.5*log((x+1.)/(x-1.));
}

float arcsech(float x){
    if(x<=0.||x>1.){
        return 0.;// arcsech is only defined for 0 < x <= 1
    }
    return arccosh(1./x);
}

float arccsch(float x){
    if(x==0.){
        return 0.;// arccsch is undefined for x = 0
    }
    return asinh(1./x);
}

float arccot(float x){
    if(x==0.){
        return 1.57079633;// Special case: arccot(0) = pi/2
    }
    return atan(1./x)+((x<0.)?3.14159265:0.);// Adjust for correct branch
}

float arcsin(float x){
    return asin(x);// Standard library function
}

float arccos(float x){
    return acos(x);// Standard library function
}

float arctan(float x){
    return atan(x);// Standard library function
}

float arcsec(float x){
    if(abs(x)<1.){
        return 0.;// arcsec is only defined for |x| >= 1
    }
    return acos(1./x);
}

float arccsc(float x){
    if(abs(x)<1.){
        return 0.;// arccsc is only defined for |x| >= 1
    }
    return asin(1./x);
}

bool isClose(float a,float b,float epsilon){
    return abs(a-b)<epsilon;
}

float frac(float num,float dom){
    if(isClose(dom,0.,EPSILON)){
        //   dom = sign(dom) * MIN_DENOMINATOR;
    }
    
    return num/dom;
}

struct Condition{
    bool predicate;
    float value;
};

float piecewise(Condition c1,Condition c2){
    if(c1.predicate){
        return c1.value;
    }else if(c2.predicate){
        return c2.value;
    }else{
        return 0.;// Default value if no condition is met
    }
}

float co(float x){
    return clamp(x,0.,1.);
}

float mo(float x){
    return mod(x,1.);
}

Condition when(bool predicate,float value){
    return Condition(predicate,value);
}

vec4 getGradientColor(float t,float v){
    t=clamp(t+v,0.,1.);// Ensure t stays in the correct range
    float t_segment=0.;
    
    // Find the appropriate gradient segment
    for(int i=1;i<MAX_GRADIENT_STOPS;i++){
        // Check the range of the current segment
        if(t<=gradientPositions[i]){
            t_segment=(t-gradientPositions[i-1])/(gradientPositions[i]-gradientPositions[i-1]);
            return mix(gradientStops[i-1],gradientStops[i],t_segment);
        }
    }
    
    // If we loop past the last stop, interpolate between the last and the first gradient stop
    return mix(gradientStops[MAX_GRADIENT_STOPS-1],gradientStops[0],t);
}

// FUNCTIONS_PLACEHOLDER

void main(void){
    float z=uFrame*.1;
    vec2 coord=(gl_FragCoord.xy/uResolution);
    vec2 uv=(2.*gl_FragCoord.xy-uResolution.xy)/uResolution.x;
    coord=coord*2.-1.;
    coord.x*=uResolution.x/uResolution.y;
    const float barScale=5.;
    vec2 bar_coord=coord*barScale;
    
    float epsilon=1e-6;
    
    // COORD_SCALE_PLACEHOLDER
    
    float x=vis_coord.x;
    float y=vis_coord.y;
    
    float r=sqrt(pow(x,2.)+pow(y,2.));
    float theta=atan(y,x);
    float psi=atan(y,abs(x));
    
    // VARIABLES_PLACEHOLDER
    
    // Sample from texture and convert to HSV
    // Apply offsets and clamp values
    if(useTexture){
        vec4 texelColor=texture2D(uSampler,vTextureCoord);
        vec3 hsv=rgb2hsv(texelColor.rgb);
        h=hsv.x;
        s=hsv.y;
        v=hsv.z;
        
        h_m=mod(h+h_o,1.);
        s_m=clamp(s+s_o,0.,1.);
        v_m=clamp(v+v_o,0.,1.);
    }else{
        h_m=mod(h_m+h_o,1.);
        s_m=clamp(s_m+s_o,0.,1.);
        v_m=clamp(v_m+v_o,0.,1.);
    }
    
    // Get the gradient color based on modified uniform value
    vec4 gradientColor=getGradientColor(u_m,u_o);
    
    // Choose between gradient and modified texture color
    vec3 finalRgb;
    finalRgb=hsv2rgb(vec3(h_m,s_m,v_m));
    
    vec4 mathColor=vec4(finalRgb,a_m);
    
    vec4 finalColor;
    
    if(enableParticles){
        float vignette=1.-smoothstep(.4,1.4,length(uv+vec2(0.,.3)));
        
        uv*=1.8;
        
        float smokeIntensity=layeredNoise1_2(uv*10.+z*4.*MOVEMENT_DIRECTION*MOVEMENT_SPEED,1.7,.7,6,.2,z);
        smokeIntensity*=pow(1.-smoothstep(-1.,1.6,uv.y),2.);
        vec4 smoke=smokeIntensity*SMOKE_COLOR*.8*vignette;
        
        //Cutting holes in smoke
        smoke*=pow(layeredNoise1_2(uv*4.+z*.5*MOVEMENT_DIRECTION*MOVEMENT_SPEED,1.8,.5,3,.2,z),2.)*1.5;
        
        vec4 particles=vec4(layeredParticles(uv,SIZE_MOD,ALPHA_MOD,LAYERS_COUNT,smokeIntensity,z),1.);
        vec4 col=particles+smoke+SMOKE_COLOR*.02;
        col=smoothstep(-.08,1.,col);
        finalColor=mix(col,mathColor,mathColor.a);
    }else{
        finalColor=mathColor;
    }
    
    if(showBars){
        vec4 bar=renderBar(bar_coord);
        vec4 barShadow=renderBarShadow(bar_coord,.04);
        vec4 bar_mixed=mix(barShadow,bar,bar.a);
        gl_FragColor=mix(finalColor,bar_mixed,bar_mixed.a);
    }else{
        gl_FragColor=finalColor;
    }
}`;
export default shader;
