import { wind } from "./root";

export function removeInternalFloatsNotInList(list: string[]) {
    for (const floatKey in wind.internalFloats) {
        if (!list.includes(floatKey)) {
            console.log('deleting ', floatKey)
            delete wind.internalFloats[floatKey];
        }
    }
}

export function setInternalFloat(internalFloat: { name: string, value: number }) {
    wind.internalFloats[internalFloat.name] = internalFloat.value;
}

export function addNewInternalFloat(name: string) {
    if (!(name in wind.internalFloats)) {
        wind.internalFloats[name] = 1.0;
    }
}